import SimpleBar from 'simplebar'
import { Div } from '~/components/html'
import { useVirtualContext } from '~/contexts'
import { css, layout, position, size, ux } from '~/libs'
import 'simplebar/dist/simplebar.css'

type ResolvedContext = Exclude<ReturnType<typeof useVirtualContext>, undefined>
type PresenterProps = { ctx: ResolvedContext, mount: (ref: HTMLDivElement) => Promise<void> }

const { VITE_VIRTUAL_LAYOUT_HEIGHT, VITE_VIRTUAL_LAYOUT_WIDTH } = import.meta.env

export function VirtualScrollbar() {
  const ctx = useVirtualContext()
  if (!ctx) throw new Error('ctx is not defined')
  const mount = async (ref: HTMLDivElement) => {
    await new Promise(resolve => setTimeout(() => resolve(true), 0)) // await 1 tick
    const instance = new SimpleBar(ref, { autoHide: true })
    ctx.setScroller(instance)
    const element = instance.getScrollElement()
    if (!element) throw new Error('element is not defined')
    ctx.setScrollElement(element)
  }
  return <Presenter ctx={ctx} mount={mount} />
}

function Presenter({ ctx, mount }: PresenterProps) {
  const styled = {
    container: css({
      ...layout({ overflowY: 'scroll' }),
      ...size({ height: '100%', width: '100%' })
    }),
    real: {
      root: css({
        ...position({
          left: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 99999 + 1 + 1
        }),
        ...size({ height: '100%', width: '100%' }),
        ...ux({ pointerEvents: 'none' })
      })
    },
    virtual: {
      root: css({
        ...position({ left: '50%', position: 'absolute', top: '50%', transform: 'translate(-50%, -50%)' }),
        ...size({ height: VITE_VIRTUAL_LAYOUT_HEIGHT, width: VITE_VIRTUAL_LAYOUT_WIDTH }),
        ...ux({ pointerEvents: 'none' })
      })
    }
  }
  return (
    <Div classList={{ [styled.virtual.root]: !ctx.isMobile(), [styled.real.root]: ctx.isMobile() }}>
      <Div class={styled.container} ref={mount}>
        <Div style={`height: ${ctx.scrollHeight()}px`} />
      </Div>
    </Div>
  )
}
